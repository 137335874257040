import { React, useReducer } from 'react'
import { auth } from './Firebase'

const LoginForm = () => {
  const [inputsContent, setInputsContent] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      password: ''
    })

  const { email, password } = inputsContent

  const handleInputChange = e => {
    setInputsContent({
      [e.target.name]: e.target.value
    })
  }

  const handleSignIn = e => {
    e.preventDefault()
    auth
      .signInWithEmailAndPassword(email, password)
      .catch(error =>
        alert(
            `Złe dane!, ${error}`
        ))
  }

  const handleSignUp = e => {
    e.preventDefault()
    auth
      .createUserWithEmailAndPassword(email, password)
      .catch(error =>
        alert(`Adres jest już w użyciu. Użyj innego., ${error}`)
      )
  }

  return (
    <form className={'login__form'}>
      <input
        className={'input'}
        placeholder={'E-mail'}
        type={'email'}
        name = {'email'}
        onChange ={handleInputChange}
        value={email}
      >
      </input>
      <input
        className={'input'}
        placeholder={'Password'}
        type={'password'}
        name = {'password'}
        onChange ={handleInputChange}
        value = {password}
      >
      </input>
      <input
        className={'button'}
        type={'submit'}
        onClick = {handleSignIn}
        value ={'Zaloguj się'}

      >
      </input>

      <input
        className={'button'}
        type={'submit'}
        onClick = {handleSignUp}
        value ={'Zarejestruj się'}
      >
      </input>
    </form>
  )
}

export default LoginForm

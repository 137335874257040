import React from 'react'

class FabricList extends React.Component {
  render () {
    const { fabrics, onDelete, status, filteredFabrics } = this.props

    return (
      <>
        <div className={'search__box'}>
          {filteredFabrics.map((element, index) => {
            return (
              <li key={index}>
                <section><span>MATERIAŁ:</span></section>   {element.fabric} <section><span>SZABLONY:</span></section> {element.pattern}
              </li>
            )
          })}
        </div>
        <section className={'section__fabrics'}>
          <h2>{status}</h2>
          <ul>
            {fabrics.map((element, index) => {
              return (
                <li key={index}>
                  <span> ID: {index} MATERIAŁ:</span> {element[1].fabric} <span>SZABLONY:</span> {element[1].pattern}{' '}

                  <button
                    className={'input'}
                    data-id={element[0]}
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                </li>
              )
            })}
          </ul>
        </section>
      </>
    )
  }
}

export default FabricList

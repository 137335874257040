// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAfmuSa3ljUFKQrs_lC7RjtvBJYjosI500',
  authDomain: 'fabrics-dawid.firebaseapp.com',
  databaseURL: 'https://fabrics-dawid-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fabrics-dawid',
  storageBucket: 'fabrics-dawid.appspot.com',
  messagingSenderId: '837605954034',
  appId: '1:837605954034:web:c24a9d474d15670b5b9b6b'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

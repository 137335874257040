import React from 'react'
import FabricForm from './FabricForm'
import FabricList from './FabricsList'
import { auth } from './Firebase'

const apiUrl =
  'https://fabrics-dawid-default-rtdb.europe-west1.firebasedatabase.app/fabrics/.json'

class FabricApp extends React.Component {
  state = {
    fabrics: [],
    status: '',
    filteredFabrics: []
  };

  sendForm (e) {
    if (this.state.fabrics.length > 100) {
      alert('Przekroczono pamięć. Usuń nieużywane dane!')
    } else {
      e.preventDefault()

      if (
        e.target[0].value === '' ||
        e.target[1].value === ''

      ) {
        alert('Uzupełnij wszystkie pola!')
      } else {
        const data = {
          fabric: e.target[0].value,
          pattern: e.target[1].value,
          id: ''
        }

        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        }

        fetch(apiUrl, options)
          .then(() => this.LoadDataFromApi())
          .then(() => {
            e.target[0].value = ''
            e.target[1].value = ''
          })
          .catch((err) => console.log(err))
      }
    }
  }

  LoadDataFromApi () {
    fetch(apiUrl)
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }
        return Promise.reject(resp)
      })
      .then((data) => {
        if (data === null || data === undefined) {
          this.setState({
            fabrics: [],
            status: 'BRAK DANYCH!'
          })
        } else {
          this.setState({
            fabrics: Object.entries(data),
            status: 'MATERIAŁY'
          })
        }

        console.log('Dane pobrane bezpośrednio z bazy')
        console.log(Array(data))

        console.log('Dane zamienione na tablice')
        console.log(this.state.fabrics)
      })
      .catch((err) => console.log(err))
  }

  deleteMeetingFromApi (e) {
    console.log(e.target.dataset.id)

    const id = e.target.dataset.id
    console.log(typeof id)

    const options = {
      method: 'DELETE'
    }

    fetch(
      `https://fabrics-dawid-default-rtdb.europe-west1.firebasedatabase.app/fabrics/${id}/.json`,
      options
    )
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err))
      .then(() => this.LoadDataFromApi())
  }

  findMeeting (e) {
    this.LoadDataFromApi()
    const fabricsArray = this.state.fabrics.map((element) => {
      return {
        fabric: element[1].fabric,
        pattern: element[1].pattern
      }
    })

    const filteredFabric = fabricsArray.filter((element) => {
      return element.fabric === e.target.value
    })

    this.setState({
      filteredFabrics: filteredFabric
    })
  }

  handleSignOut = () => auth.signOut()

  render () {
    return (
      <>
        <h2>Zapisz dane</h2>
        <button
          onClick={this.handleSignOut.bind(this)}
          className={'button'}
        >Wyloguj
        </button>
        <FabricForm
          onClick={this.LoadDataFromApi.bind(this)}
          onSubmit={this.sendForm.bind(this)}
          onChange={this.findMeeting.bind(this)}
        >
        </FabricForm>
        <FabricList
          filteredFabrics={this.state.filteredFabrics}
          status={this.state.status}
          fabrics={this.state.fabrics}
          onDelete={this.deleteMeetingFromApi.bind(this)}
        >
        </FabricList>
      </>
    )
  }
}

export default FabricApp

import { React, useState, useEffect } from 'react'
import FabricApp from './components/FabricsApp'
import LoginForm from './components/LoginForm'
import { auth } from './components/Firebase'

export const App = () => {
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
        localStorage.setItem('user', user.uid)
      } else {
        setCurrentUser(null)
        localStorage.removeItem('user')
      }
    })
  }, [])

  return (
    <div className={'App'}>
      <h1>JAKIE SZABLONY UŻYĆ?</h1>
      {currentUser
        ? (
          <>
            <FabricApp></FabricApp>
          </>
          )
        : (
          <LoginForm></LoginForm>
          )
    }

    </div>
  )
}

export default App

import React from 'react'

class FabricForm extends React.Component {
  render () {
    const { onSubmit, onClick, onChange, searchValue } = this.props

    return (
      <>
        <form onSubmit={onSubmit}>
          <input
            className={'input'}
            placeholder={'Nazwa materiału'}
            name={'name'}
          />
          <input
            className={'input'}
            placeholder={'Rodzaj szablonów'}
            name={'pattern'}
          />
          <br></br>
          <input
            value={'Zapisz'}
            className={'button input'}
            type={'submit'}
          >
          </input>
        </form>
        <h2>Wyszukaj materiał</h2>
        <input
          value={searchValue}
          onChange={onChange}
          placeholder={'Szukaj'}
          className={'input'}
        >
        </input>
        <button
          className={'button'}
          onClick={onClick}
        >
          Wyświetl wszystkie materiały
        </button>
      </>
    )
  }
}

export default FabricForm
